/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  Button,
  CloseIcon,
  Flex,
  VideoIcon,
} from '@bottlebooks/gatsby-theme-base';
import FeatureOverview from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src/components/FeatureOverview';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import Dialog from '@reach/dialog';
import React from 'react';
import { jsx } from 'theme-ui';

// This is a place for starter events to extend.
export default function TopMenuItems({ ...rest }) {
  const [isLearnMoreVisible, setIsLearnMoreVisible] = React.useState(false);
  const link = useLink();
  return (
    <Box {...rest}>
      <Button
        onClick={() => setIsLearnMoreVisible(true)}
        sx={{ display: ['none', 'inline-block'] }}
      >
        <VideoIcon sx={{ marginRight: 2, height: 25, width: 25 }} />
        Learn more
      </Button>
      {isLearnMoreVisible && (
        <Dialog
          onDismiss={() => setIsLearnMoreVisible(false)}
          sx={{
            width: ['100vw', 600],
            marginTop: [0, '10vh'],
            position: 'relative',
          }}
        >
          <Button
            variant="text"
            onClick={() => setIsLearnMoreVisible(false)}
            sx={{ position: 'absolute', top: 2, right: 2 }}
          >
            <CloseIcon size="xsmall" />
          </Button>
          <FeatureOverview />
          <Flex justify="center" sx={{ marginTop: 4 }}>
            <Button
              variant="primary"
              onClick={() => {
                // Don't show the content again after the user has read this page
                // TODO: not sure why the hook doesn't work here, but resorting to hard code for now.
                typeof window !== 'undefined' &&
                  localStorage.setItem(
                    'educationalContentHasBeenShown',
                    'true'
                  );
                setIsLearnMoreVisible(false);
              }}
              to={link.products()}
            >
              Add products to my catalog
            </Button>
          </Flex>
        </Dialog>
      )}
    </Box>
  );
}
