/** @jsx jsx */
import { Box, View } from '@bottlebooks/gatsby-design-system';
import { jsx } from 'theme-ui';
import cssOverlay from '@bottlebooks/gatsby-theme-event/src/helpers/cssOverlay';
import Image from 'gatsby-plugin-sanity-image';

export default function SliderImage({
  image,
  children,
  height,
  alt = 'Image',
  parallax = false,
  withOverlay,
}) {
  return (
    <View>
      <Box
        sx={{
          padding: 0,
          margin: 0,
          // @ts-ignore Not sure why TS complains about undefined.
          '::after': {
            ...cssOverlay(),
            backgroundColor: 'none',
            background:
              'linear-gradient(to bottom, rgba(0,0,0,0) 50%, rgba(0,0,0,0.3) 100%)',
          },
        }}
      >
        {image && (
          <Image
            {...image}
            loading="eager"
            width={1280}
            sizes="100vw"
            // height={Array.isArray(height) ? height[height.length - 1] : height} // TODO height doesn't work due to a bug in sanity/image-url upstream library: https://github.com/sanity-io/image-url/issues/32
            alt={alt}
            sx={{
              position: 'relative',
              objectFit: 'cover',
              // Always ensure the images are full-width
              width: '100%',
              height: height,
            }}
          />
        )}
      </Box>
      {children}
    </View>
  );
}
