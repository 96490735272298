// @ts-check
/** @jsx jsx */
import { gql } from '@apollo/client';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import {
  ToggleFavorite,
  useFavorite,
} from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import {
  hasDifferentExhibitor,
  ProductHeaderDetails,
  ProductHeaderDetailsFragment,
  ProductName,
  ProductNameFragment,
  ProductProducer,
  ProductProducerNameFragment,
  ProductStand,
  ProductStandFragment,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductHeader';
import ProductImageCol, {
  ProductImageFragment_large,
} from '@bottlebooks/gatsby-theme-event/src/components/ProductPage/ProductImageCol';
import ProductNavigation from '@bottlebooks/gatsby-theme-event/src/components/ProductPage/ProductNavigation';
import {
  ProductMainCol,
  ProductPageHeaderRow,
} from '@bottlebooks/gatsby-theme-event/src/components/ProductPage/ProductPageHeader';
import ProductNumber from '../../../../components/ProductNumber';
import {
  Box,
  Text,
  Container,
  SemiTitle,
} from '@bottlebooks/gatsby-theme-base';
import React from 'react';

export default function EventProductPageHeader({
  product,
  next,
  previous,
  standPath,
  overlapImage,
}) {
  const link = useLink();
  const favorite = useFavorite(
    product && { type: 'product', id: product.productId }
  );
  const hasOtherExhibitor = hasDifferentExhibitor(product);
  return (
    <React.Fragment>
      <ProductPageHeaderRow sx={{ justifyContent: 'center' }}>
        <ProductImageCol product={product} overlap={overlapImage} />
        <ProductMainCol>
          <ProductNumber product={product} sx={{ marginBottom: 2 }} />
          <ProductStand
            stand={product?.stand}
            exhibitor={hasOtherExhibitor && product?.exhibitor}
            to={hasOtherExhibitor && standPath}
          />
          <ProductProducer
            producer={product?.producer}
            to={link.producer(product.producer)}
          />
          <ProductName as="h1" product={product} />
          <ProductHeaderDetails product={product} showMorePricingInfo={true} />
          <ToggleFavorite
            sx={{ marginTop: 3, marginLeft: -2, padding: 0, paddingRight: 3 }}
            isToggled={favorite.isSet}
            onClick={favorite.toggle}
          />
        </ProductMainCol>

        <ProductNavigation previous={previous} next={next} />
      </ProductPageHeaderRow>
      <Container sx={{ paddingX: [0, `${100 / 12}%`] }}>
        <Stockists product={product} />
      </Container>
    </React.Fragment>
  );
}

function Stockists({ product }) {
  const hasStockistDetails = !!product.listOfStockists?.text;
  if (!hasStockistDetails) return null;
  return (
    <Box
      sx={{
        backgroundColor: '#fed40b',
        padding: 3,
      }}
    >
      <SemiTitle
        sx={{
          margin: 0,
          padding: 0,
          color: '#333',
        }}
      >
        UK Stockists
      </SemiTitle>

      <Text variant="large" sx={{ color: '#333' }}>
        {product?.listOfStockists?.text}
      </Text>
    </Box>
  );
}

export const fragment = graphql`
  fragment bb_EventProductPageHeader on Bottlebooks_Product {
    productId
    ...bb_ProductHeaderDetails
    producer {
      ...useLink_bb_Producer
    }
  }

  fragment EventProductPageHeader on Product {
    ...ProductImageLarge
    ...ProductStand
    ...ProductProducerName
    ...ProductName
    ...ProductHeaderDetails
  }
`;

export const EventProductPageHeaderFragment = gql`
  fragment EventProductPageHeader on Product {
    productId
    ...ProductImageLarge
    ...ProductStand
    ...ProductProducerName
    ...ProductName
    ...ProductHeaderDetails
  }

  ${ProductImageFragment_large}
  ${ProductStandFragment}
  ${ProductProducerNameFragment}
  ${ProductNameFragment}
  ${ProductHeaderDetailsFragment}
`;
