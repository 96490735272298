/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { gql as apolloGql } from '@apollo/client';
import Card from '@bottlebooks/gatsby-design-system/src/components/Card/Card';
import {
  Box,
  Flex,
  Label,
  Badge,
  Link,
  SemiTitle,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import { useFavorite } from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src';
import ToggleFavorite from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src/components/ToggleFavorite';
import { TastingNoteButton } from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-tastingnotes/src';
import ListRowBody from '@bottlebooks/gatsby-theme-event/src/components/List/ListRowBody';
import {
  ProductHeaderDetails,
  ProductHeaderFragment,
  ProductStand,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductHeader';
import ProductImage, {
  ProductImageFragment_large,
} from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductImage';
import BaseProductName from '@bottlebooks/gatsby-theme-event/src/components/Product/ProductName';
import ProductFeatureIcon from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductFeatureIcon';
import ProductRequestSampleWithSnipcartButtonForList from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductRequestSampleWithSnipcartButtonForList';
import useSiteConfig from '@bottlebooks/gatsby-theme-event/src/components/useSiteConfig';
import { Trans } from '@lingui/macro';
import { graphql as gatsbyGraphQL } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import ProductNumber from '../../../../components/ProductNumber';

/** @param {{ product: object; className?:  string; elevation?:Parameters<Card>[0]["elevation"]; [x: string]: any; }} props */
export default function ProductCard({
  product,
  className,
  elevation,
  ...rest
}) {
  const favorite = useFavorite(
    product && { type: 'product', id: product.productId }
  );
  return (
    // If flexWrap is set to wrap, the bottleImage placeholder will be cut off.
    <Card
      sx={{ flexWrap: 'nowrap' }}
      elevation={elevation}
      className={className}
    >
      <Link
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          flexGrow: 1,
          color: 'inherit',
          position: 'relative',
        }}
        {...rest}
      >
        <ProductImage
          product={product}
          size="large"
          sx={{
            maxWidth: '35%',
            height: 'initial',
            margin: 'auto',
            paddingRight: 0,
            '.gatsby-image-wrapper': { paddingTop: '200%', height: 0 },
          }}
        />
        <ListRowBody sx={{ marginTop: 4 }}>
          <ProductNumber product={product} sx={{ marginBottom: 2 }} />
          {/* <ProductStand stand={product?.stand} exhibitor={undefined} to="" /> */}
          <Label color="text">{product?.exhibitor.name}</Label>
          {product?.exhibitor.name !== product?.producer.name && (
            <Text variant="smallest" sx={{ marginTop: 2 }}>
              {product?.producer.name}
            </Text>
          )}
          <SemiTitle sx={{ paddingTop: 1, paddingBottom: 2 }}>
            <BaseProductName {...product} />
          </SemiTitle>
          <ProductHeaderDetails product={product} />
          <NewToMarket product={product} sx={{ marginTop: 2 }} />
        </ListRowBody>
        <ProductFeatures
          product={product}
          sx={{ position: 'absolute', bottom: 2.5, right: 2.5 }}
        />
      </Link>
      {!favorite.isLoading && (
        <ToggleFavorite
          isToggled={favorite.isSet}
          variant="small"
          sx={{ position: 'absolute', top: 0, right: 0 }}
          onClick={() => favorite.toggle()}
        />
      )}
      <ProductCardActionsBar
        product={product}
        sx={{ borderTop: 1, borderTopColor: 'border' }}
      />
    </Card>
  );
}

function ProductCardActionsBar({ product, ...rest }) {
  const { sampleRequestsWithSnipcartEnabled, tastingNotesEnabled } =
    useSiteConfig();
  const { getButtonProps, getIconProps, tastingNote } = TastingNoteButton.use({
    productId: product.productId,
  });
  // TODO also check if logged in?
  if (!sampleRequestsWithSnipcartEnabled && !tastingNotesEnabled) return null;

  return (
    <Flex
      gap={2}
      wrap="wrap"
      align="center"
      sx={{
        width: '100%',
        backgroundColor: 'light',
        padding: 2,
        paddingBottom: 0,
      }}
      {...rest}
    >
      {tastingNote ? (
        <>
          <TastingNoteButton
            {...getButtonProps()}
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              marginBottom: 2,
            }}
            variant="text"
          >
            <TastingNoteButton.Icon
              {...getIconProps({ size: 'smallMedium' })}
              sx={{ flexShrink: 0 }}
            />
            {tastingNote.note && (
              <Text
                variant="small"
                sx={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {tastingNote.note}
              </Text>
            )}
          </TastingNoteButton>
        </>
      ) : (
        <TastingNoteButton.Large
          productId={product.productId}
          sx={{ marginBottom: 2 }}
        />
      )}

      {sampleRequestsWithSnipcartEnabled && (
        <ProductRequestSampleWithSnipcartButtonForList
          product={product}
          sx={{ flexShrink: 0, marginLeft: 'auto', marginBottom: 2 }}
        />
      )}
    </Flex>
  );
}

function ProductFeatures({ product, ...rest }) {
  if (!product.features?.length) return null;
  return (
    <Flex sx={{ width: '100%', justifyContent: 'flex-end' }} {...rest}>
      {product.features?.map((type) => (
        // Product features have to be queried separately by the parent.
        <ProductFeatureIcon key={type} variant="onPrimary" type={type} />
      ))}
    </Flex>
  );
}

function NewToMarket({ product, variant = 'default', ...rest }) {
  if (
    product.listOfStockists?.text?.toLowerCase() !==
    'New to market'.toLowerCase()
  )
    return null;
  return (
    <Box {...rest}>
      <Badge
        sx={{
          backgroundColor: 'accent',
          // Avoid hover effect
          color: 'black',
          paddingX: 2,
          paddingY: 0,
        }}
      >
        <Trans>{product.listOfStockists?.text}</Trans>
      </Badge>
    </Box>
  );
}

// HEADSUP: Product features have to be queried separately by the parent.

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment bb_ProductCard on Bottlebooks_Product {
    ...bb_ProductRequestSampleWithSnipcartButton
    ...bb_ProductHeader
  }
  fragment ProductCard on Product {
    ...ProductImageLarge
    ...ProductHeader
  }

  # A fragment for the first page of products. It has more detailed images.
  fragment ProductCard_first on Product {
    ...ProductImageLarge
  }
`;

ProductCard.fragment = apolloGql/* GraphQL */ `
  fragment ProductCard on Product {
    ...ProductImageLarge
    ...ProductHeader
  }
  ${ProductImageFragment_large}
  ${ProductHeaderFragment}
`;
