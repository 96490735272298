/** @jsx jsx */
// @ts-check
import Card from '@bottlebooks/gatsby-design-system/src/components/Card/Card';
import { Box, Flex, SemiTitle, Text } from '@bottlebooks/gatsby-theme-base';

import { graphql as gatsbyGraphQL } from 'gatsby';
import { jsx } from 'theme-ui';
import {
  ToggleFavorite,
  useFavorite,
} from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src';
import BrandLogo from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandLogo';
import BrandRegion from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandRegion';
import SeekingDistribution from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandSeekingDistribution';
import OfferingBadge from '@bottlebooks/gatsby-theme-event/src/components/Brand/OfferingBadge';
import ExhibitorStand from '@bottlebooks/gatsby-theme-event/src/components/Exhibitor/ExhibitorStand';
import ListRow from '@bottlebooks/gatsby-theme-event/src/components/List/ListRow';
import ListRowBody from '@bottlebooks/gatsby-theme-event/src/components/List/ListRowBody';

/**
 *
 * @param {object} props
 * @param {object} props.exhibitor
 * @param {string} [props.to]
 * @param {string} [props.className]
 * @param {React.CSSProperties} [props.style]
 * @param {Parameters<Card>[0]["elevation"]} [props.elevation]
 * @param {React.MouseEventHandler} [props.onClick]
 * @param {boolean} [props.alwaysShowCharacteristics] If set, shows the characteristics of the exhibitor.
 * @param {React.ReactNode} [props.children]
 * @returns
 */
export default function ExhibitorCard({
  exhibitor,
  children,
  elevation,
  className,
  style,
  alwaysShowCharacteristics,
  ...rest
}) {
  // TODO use registrationId: exhibitor.registrationId,
  const favorite = useFavorite(
    exhibitor && { type: 'registration', id: exhibitor.exhibitorId }
  );
  return (
    <Card elevation={elevation} className={className} style={style}>
      <ListRow
        {...rest}
        sx={{
          boxSizing: 'border-box',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: 'default',
        }}
      >
        <Flex
          justify="center"
          sx={{
            paddingTop: 3,
            paddingBottom: 1,
            width: '100%',
            position: 'relative',
          }}
        >
          <Flex justify="center" sx={{ width: '100%' }}>
            <BrandLogo brand={exhibitor} onDark={true} size="large" />
          </Flex>
          <SeekingDistribution
            brand={exhibitor}
            variant="small"
            sx={{
              position: 'absolute',
              bottom: 0,
              display: 'block',
              marginX: 'auto',
            }}
          />
        </Flex>
        <ListRowBody
          sx={{
            marginBottom: 3,
            width: '100%',
            flex: '0 0 auto',
            paddingTop: 1,
            paddingBottom: 0,
          }}
        >
          <ExhibitorStand exhibitor={exhibitor} />
          <SemiTitle sx={{ paddingBottom: 1, paddingTop: 0 }}>
            {exhibitor.name}
          </SemiTitle>
          <BrandRegion brand={exhibitor} sx={{ color: 'text' }} />
          {(exhibitor.characteristics?.length > 0 ||
            alwaysShowCharacteristics) && (
            <Box sx={{ minHeight: '18px' }}>
              <Text
                variant="smallest"
                sx={{ color: 'lighterText', marginTop: 1 }}
              >
                {exhibitor.characteristics
                  ?.map((characteristc) =>
                    characteristc
                      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
                        return index === 0
                          ? word.toUpperCase()
                          : word.toLowerCase();
                      })
                      .replace(/\s+/g, '')
                  )
                  .sort((a, b) => (a > b ? 1 : -1))
                  .join(', ')}
              </Text>
            </Box>
          )}
        </ListRowBody>
      </ListRow>
      <ToggleFavorite
        variant="small"
        isToggled={favorite.isSet}
        onClick={() => favorite.toggle()}
        sx={{ position: 'absolute', top: 0, right: 0 }}
      />

      <Flex
        direction="column"
        sx={{ position: 'absolute', bottom: 3, right: 3 }}
      >
        {children}
      </Flex>
    </Card>
  );
}

export function BrandFeatures({ brand }) {
  if (!brand.offeringTypes?.length) return null;
  return (
    <Flex
      justify="flex-end"
      align="flex-end"
      gap={2}
      sx={{
        width: '100%',
        paddingRight: 2,
        paddingBottom: 2,
        position: 'relative', // Needed to stay above image backgrounds
        marginLeft: 'auto',
      }}
    >
      {brand.offeringTypes?.map((type) => (
        <OfferingBadge key={type} variant="onPrimary" type={type} />
      ))}
    </Flex>
  );
}

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment bb_ExhibitorCard on Bottlebooks_Exhibitor {
    name
    ...bb_BrandRegion
    offeringTypes
  }
  fragment bb_ExhibitorCard_Registration on Bottlebooks_Registration {
    registrationId
  }

  fragment bb_ExhibitorCard_RegisteredBrand on Bottlebooks_RegisteredBrand {
    ...bb_RegisteredBrand_SeekingDistribution
  }

  fragment ExhibitorCard on Exhibitor {
    ...BrandLogoLarge
  }

  # A fragment for the first page of exhibitors. It has more detailed images.
  fragment ExhibitorCard_first on Exhibitor {
    ...BrandLogoLarge
  }
`;
