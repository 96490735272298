/** @jsx jsx */
// @ts-check
import {
  Box,
  Flex,
  Label,
  SemiTitle,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import { Trans } from '@lingui/macro';
import { graphql as gatsbyGraphQL } from 'gatsby';
import { jsx } from 'theme-ui';
import {
  ToggleFavorite,
  useFavorite,
} from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src';
import BrandLogo from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandLogo';
import BrandRegion from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandRegion';
import SeekingDistribution from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandSeekingDistribution';
import ExhibitorStand from '@bottlebooks/gatsby-theme-event/src/components/Exhibitor/ExhibitorStand';
import ListRow from '@bottlebooks/gatsby-theme-event/src/components/List/ListRow';
import ListRowBody from '@bottlebooks/gatsby-theme-event/src/components/List/ListRowBody';

/**
 *
 * @param {object} props
 * @param {object} props.producer
 * @param {"default" | "info"} [props.variant="default"] 'info' doesn't show favorites toggle
 * @param {string} [props.to]
 * @param {React.MouseEventHandler} [props.onClick]
 * @param {React.ReactNode} [props.children]
 * @returns
 */
export default function ProducerCard({
  producer: baseProducer,
  children,
  variant = 'default',
  ...rest
}) {
  // This is necessary in order to support multiple events in a single site.
  // ProducerCard is built to use RegisteredBrand. However, this is not in the Gatsby source yet.
  // Because it is not in Gatsby source, we only have access to the data from the first event.
  // This data is available in allProducer in Gatsby source, though. This is why we build an ugly
  // composite object here.
  const producer = {
    ...baseProducer,
    profile: { ...baseProducer, ...baseProducer.profile },
  };
  // TODO use registrationId: exhibitor.registrationId,
  const favorite = useFavorite(
    producer && { type: 'registration', id: producer.exhibitorId }
  );
  const isPresentedByAnotherExhbitor =
    producer.producerId !== producer.exhibitorId;
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'grid',
        minHeight: 200,
        boxSizing: 'border-box',
        backgroundColor: 'background',
        boxShadow: 'medium',
        borderRadius: 'default',
        // Only add hover to links. The `&` is replaced by the class name automatically.
        'a[href]&:hover': {
          boxShadow: 'medium',
          ' > .bottlebooks-bgimg': {
            transform: 'scale(1.01)',
            '::after': {
              backgroundColor: 'overlaySecondary',
            },
          },
        },
      }}
    >
      <ListRow
        {...rest}
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Flex
          justify="center"
          sx={{
            paddingTop: 3,
            paddingBottom: 1,
            width: '100%',
            position: 'relative',
          }}
        >
          <Flex justify="center" sx={{ width: '100%' }}>
            <BrandLogo brand={producer.profile} onDark={true} size="large" />
          </Flex>

          <SeekingDistribution
            brand={producer}
            variant="small"
            sx={{
              position: 'absolute',
              bottom: 0,
              display: 'block',
              marginX: 'auto',
            }}
          />
        </Flex>
        <ListRowBody
          sx={{
            marginBottom: 3,
            width: '100%',
            flex: '0 0 auto',
            paddingTop: 1,
            paddingBottom: 0,
          }}
        >
          <ExhibitorStand exhibitor={producer} />
          <SemiTitle sx={{ paddingBottom: 1, paddingTop: 0 }}>
            {producer.name}
          </SemiTitle>
          <BrandRegion brand={producer.profile} />
        </ListRowBody>
      </ListRow>
      <Flex direction="column" sx={{ width: '100%' }}>
        {children}
      </Flex>
      {variant === 'default' && (
        <Flex
          justify="space-between"
          align="center"
          sx={{
            width: '100%',
            position: 'absolute',
            top: 0,
            right: 0,
            ...(isPresentedByAnotherExhbitor && {
              backgroundColor: 'light',
              borderBottom: 1,
              borderColor: 'border',
            }),
          }}
        >
          {isPresentedByAnotherExhbitor ? (
            <Box sx={{ paddingLeft: 2 }}>
              <Label variant="small">
                <Trans>Presented by</Trans>
              </Label>
              <Text variant="smallest">{producer.exhibitor.name}</Text>
            </Box>
          ) : (
            // Empty item to keep toggle right-aligned
            <Box />
          )}
          <ToggleFavorite
            variant="small"
            isToggled={favorite.isSet}
            onClick={() => favorite.toggle()}
          />
        </Flex>
      )}
    </Box>
  );
}

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment bb_ProducerCard_RegisteredBrand on Bottlebooks_RegisteredBrand {
    brandId
    name
    exhibitorId: companyId
    registrationId
    ...bb_RegisteredBrand_SeekingDistribution
    ...bb_RegisteredBrand_BrandLogoLarge
    ...bb_RegisteredBrand_BrandRegion
  }
`;
