// @ts-check
/** @jsx jsx */
import { gql } from '@apollo/client';
import { Box, Text, Title } from '@bottlebooks/gatsby-design-system';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

/**
 * This really should be done like this at all.
 * The purpose of this is to display sustainability-relevant information, not bottle details.
 * However, due to time constraints, we're putting it here for now.
 */

function hasContent(bottleVariant) {
  if (!bottleVariant) return false;
  if (bottleVariant.GTIN) return true;
  if (bottleVariant.bottleSize) return true;
  if (bottleVariant.bottleNetWeight) return true;
  return false;
}
export default function BottleVariant({ bottleVariant, ...rest }) {
  if (!hasContent(bottleVariant)) return null;

  const bottleWeightsString = [
    bottleVariant.bottleWeight && `${bottleVariant.bottleWeight}g`,
  ]
    .filter(Boolean)
    .join(' / ');
  return (
    <Box {...rest}>
      {/* <Value title={<Trans comment="Bottle size">Size</Trans>}>
        {bottleVariant.bottleSize}
      </Value> */}
      <Title>{bottleWeightsString}</Title>
      <Value title={<Trans>GTIN</Trans>}>{bottleVariant.GTIN}</Value>
    </Box>
  );
}
BottleVariant.hasContent = hasContent;
/**
 * This picks the fields from the variant that are actually used in this component.
 * We can use this in the parent component to determine the unique values.
 * @param {object} variant
 * @param {string?} variant.GTIN
 * @param {string?} variant.bottleSize
 * @returns
 */
BottleVariant.getContent = ({ GTIN, bottleSize }) => ({ GTIN, bottleSize });

function Value({ title, children, ...rest }) {
  if (!children) return null;
  return (
    <Text {...rest}>
      {title}: {children}
    </Text>
  );
}

export const fragment = graphql`
  fragment bb_BottleVariant on Bottlebooks_ProductBottleVariant {
    GTIN
    bottleSize
  }
`;

BottleVariant.fragment = gql`
  fragment BottleVariant on ProductBottleVariant {
    GTIN
    bottleSize
  }
`;
