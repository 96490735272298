/**  @jsx jsx */
import BaseBaseLayout from '@bottlebooks/gatsby-theme-event/src/components/Layout/BaseLayout';
import React from 'react';
import { Helmet } from 'react-helmet';
import { jsx } from 'theme-ui';

export default function BaseLayout({ children, ...rest }) {
  return (
    <React.Fragment>
      {/* We need to inline stylesheet because we are loading these fonts directly. */}
      <Helmet>
        <style type="text/css">
          {`@font-face {
            font-family: RockfordSans;
            font-weight: 700;
            src: url(//discovercaliforniawines.com/wp-content/themes/dcw/fonts/RockfordSans/normal_normal_bold.woff2) format("woff");
          }
          @font-face {
            font-family: RockfordSans;
            font-weight: 400;
            src: url(//discovercaliforniawines.com/wp-content/themes/dcw/fonts/RockfordSans/normal_normal_normal.woff2) format("woff");
          }
          `}
        </style>
      </Helmet>
      <BaseBaseLayout {...rest}>{children}</BaseBaseLayout>
    </React.Fragment>
  );
}
