// @ts-check
/** @jsx jsx */
import {
  Box,
  Button,
  Col,
  Container,
  DownloadIcon,
  Flex,
  Row,
  Text,
} from '@bottlebooks/gatsby-theme-base';
import { Trans } from '@lingui/macro';
import { graphql as gatsbyGraphQL } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import CatalogExportDialog from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-favorites/src/components/CatalogExportDialog';
import useAuthentication from '@bottlebooks/gatsby-theme-event/plugins/gatsby-plugin-firebase-auth/src/useAuthentication';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import EventBanner from '@bottlebooks/gatsby-theme-event/src/components/Event/EventBanner';
import EmptyState from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/EmptyState';
import FiltersToggleButton from '@bottlebooks/gatsby-theme-event/src/components/Filters/FiltersToggleButton';
import createFacets from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFacets';
import ProductFacetsBar from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductFacetsBar';
import reducer, {
  initialState,
  searchTermChanged,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/productFilterReducer';
import ProductFilters from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductFilters';
import ProductSearchTermFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Product/ProductSearchTermFacet';
import useFilter from '@bottlebooks/gatsby-theme-event/src/components/Filters/useFilter';
import useSyncedLocation, {
  getStateFromLocation,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/useSyncedLocation';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import ListRow from '@bottlebooks/gatsby-theme-event/src/components/List/ListRow';
import ProductCard from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductCard';
import ProductsList from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductsList';
import ProductsOrderedByExhibitor from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductsOrderedByExhibitor';
import SEO from '@bottlebooks/gatsby-theme-event/src/components/seo';
import SearchHeader from '@bottlebooks/gatsby-theme-event/src/components//ProductsPage/SearchHeader';
import useSearch from '@bottlebooks/gatsby-theme-event/src/components//ProductsPage/useProductSearch';

const PAGE_SIZE = 5;

export default function ProductsPage({
  products,
  event,
  location,
  facetConfig,
  locale,
  withEventBanner,
  withEventBannerOverlay,
}) {
  const link = useLink();
  const stateFromLocation = getStateFromLocation(location, initialState);
  /** @type {[state: typeof initialState, dispatch: React.Dispatch<[event: string, payload?: any]>]} */
  const [state, dispatch] = React.useReducer(reducer, stateFromLocation);
  const [orderBy, setOrderBy] = React.useState(
    /** @type {'productName' | 'exhibitorName' | 'table'} */ ('productName')
  );
  // Change the URL hash when the filters change.
  // We use URL hash also for other state information such as orderBy
  useSyncedLocation(
    { ...state, orderBy: [orderBy] },
    { initialState: { ...initialState, orderBy: [] }, location }
  );
  // Filter the products by term and facets.
  const searchedProducts = useSearch(products, state.searchTerm);
  const { items, facets } = useFilter(searchedProducts, {
    state,
    facetDefinitions: createFacets(facetConfig, state),
    // sortOptions,
  });
  const [limit, setLimit] = React.useState(PAGE_SIZE);
  const [filtersAreVisible, setFiltersAreVisible] = React.useState(false);
  const [exportIsVisible, setExportIsVisible] = React.useState(false);
  const { requireAuthentication } = useAuthentication();

  function dispatchFilter(event, isActive) {
    dispatch(event);
    setLimit(PAGE_SIZE);
    if (isActive) setFiltersAreVisible(false);
  }

  const groupedItems = items?.length
    ? orderBy === 'exhibitorName'
      ? Object.values(
          items?.reduce((acc, product) => {
            if (!acc[product.exhibitorId])
              acc[product.exhibitorId] = {
                key: product.exhibitorId,
                exhibitor: {
                  ...product.exhibitor,
                  // We could get this through the GraphQL, but it would be redundant info
                  // We need this for link.exhibitor() in ProductsGroupedByExhibitor
                  exhibitorId: product.exhibitorId,
                },
                products: [],
              };
            acc[product.exhibitorId].products.push(product);
            return acc;
          }, {})
        ).sort((a, b) => (a.exhibitor.name > b.exhibitor.name ? 1 : -1))
      : items
    : [];

  const limitedItems = groupedItems?.slice(
    0,
    orderBy === 'productName' ? limit * 4 : limit
  );
  const hasMore = groupedItems?.length > limitedItems.length;
  const page = {
    path: location.href,
  };

  return (
    <Layout locale={locale}>
      <SEO page={page} />
      {withEventBanner && (
        <EventBanner event={event} withOverlay={withEventBannerOverlay} />
      )}
      <Box sx={{ backgroundColor: 'light' }}>
        <Container fluid sx={{ maxWidth: 'container.fluid', paddingBottom: 6 }}>
          <Flex
            justify="space-between"
            sx={{
              alignItems: ['flex-start', null, 'center'],
              flexDirection: ['column', null, 'row'],
              paddingY: 2,
            }}
          >
            <Flex
              align="center"
              gap={2}
              sx={{
                justifyContent: ['space-between', null, 'flex-start'],
                flexGrow: [1, null, 0],
                width: ['100%', null, 'auto'],
              }}
            >
              <ProductSearchTermFacet
                value={state.searchTerm}
                onClick={() => setFiltersAreVisible(true)}
                // Close filters when enter is pressed
                onEnter={() => setFiltersAreVisible(false)}
                onChange={(value) => dispatch(searchTermChanged(value))}
                sx={{
                  paddingY: 2,
                  width: ['100%', null, 300],
                  paddingRight: 2,
                }}
              />
              <FiltersToggleButton
                onToggle={() => setFiltersAreVisible(!filtersAreVisible)}
                filtersHidden={!filtersAreVisible}
                sx={{ padding: 0 }}
              />
            </Flex>
            <Box
              sx={{ marginLeft: [0, null, 'auto'], marginTop: [3, null, 0] }}
            >
              <Text
                variant="small"
                sx={{ display: 'inline-block', marginRight: 2 }}
              >
                <Trans>Order by:</Trans>
              </Text>

              <Box
                sx={{ backgroundColor: 'background', display: 'inline-block' }}
              >
                <Button
                  variant={orderBy === 'exhibitorName' ? 'primary' : 'outline'}
                  onClick={() => setOrderBy('exhibitorName')}
                  sx={{
                    paddingX: 2,
                    paddingY: 1,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <Trans>Importer name</Trans>
                </Button>
                <Button
                  variant={orderBy === 'productName' ? 'primary' : 'outline'}
                  onClick={() => setOrderBy('productName')}
                  sx={{
                    paddingX: 2,
                    paddingY: 1,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <Trans>Product name</Trans>
                </Button>
              </Box>
            </Box>
          </Flex>

          <ProductFilters
            facets={facets}
            dispatch={dispatchFilter}
            state={state}
            totalCount={products.length}
            count={items.length}
            event={event}
            sx={{
              gap: 3,
              display: filtersAreVisible ? 'grid' : 'none',
              gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            }}
          />

          <ProductFacetsBar dispatch={dispatch} state={state} />

          <Flex
            gap={2}
            align="baseline"
            justify="space-between"
            sx={{
              flexDirection: ['column', 'row'],
              paddingTop: 4,
              paddingBottom: 2,
            }}
          >
            <SearchHeader totalCount={products.length} count={items.length} />
            <Button
              variant="text"
              onClick={() => {
                if (!requireAuthentication()) return;
                setExportIsVisible(true);
              }}
              sx={{
                color: 'primary',
                display: ['none', 'inline-block'],
                padding: 0,
              }}
            >
              <DownloadIcon sx={{ marginRight: 1 }} />
              <Text variant="small" sx={{ display: 'inline-block' }}>
                <Trans>Download my tasting booklet</Trans>
              </Text>
            </Button>
          </Flex>

          <Row>
            <Col sx={{ width: '100%' }}>
              {!items || !items.length ? (
                <EmptyState />
              ) : (
                <ProductsList
                  key={orderBy}
                  count={items.length}
                  sx={{
                    marginX: [-3, -2],
                    marginBottom: -2,
                    // When grouped by Exhibitor, force full width
                    ...(orderBy === 'exhibitorName' && {
                      gridTemplateColumns: ['1fr', '1fr'],
                    }),
                  }}
                >
                  {limitedItems.map((item) =>
                    orderBy === 'productName' ? (
                      <ProductCard
                        key={item.id}
                        product={item}
                        to={link.product(item)}
                      />
                    ) : (
                      <ProductsOrderedByExhibitor
                        key={item.key}
                        exhibitor={item.exhibitor}
                        products={item.products}
                        sx={{ marginBottom: 4 }}
                      />
                    )
                  )}
                  {hasMore && (
                    <Button
                      as={ListRow}
                      variant="empty"
                      onClick={() => setLimit(limit + PAGE_SIZE)}
                      sx={{
                        margin: [0, 2],
                        paddingX: 4,
                        paddingY: 3,
                        textAlign: 'center',
                        display: 'block',
                        border: 0,
                        variant: 'text.small',
                      }}
                    >
                      <Trans>Show more</Trans>
                    </Button>
                  )}
                </ProductsList>
              )}
            </Col>
          </Row>
          {exportIsVisible && (
            <CatalogExportDialog
              locale={locale}
              collectionId={event.collectionId}
              onClose={() => setExportIsVisible(false)}
            />
          )}
        </Container>
      </Box>
    </Layout>
  );
}

export const fragment = gatsbyGraphQL/* GraphQL */ `
  fragment bb_ProductsPageBase on Bottlebooks_Product {
    productId
    ...bb_ProductFilters
    ...bb_ProductListRow
    ...bb_ProductCard
    ...bb_Search_Product
    # ...useLink_bb_Product # TODO This doesn't work because exhibitorId isn't resolved correctly on RegisteredProduct.product.
  }

  fragment bb_ProductsPageBase_Event on Bottlebooks_Event {
    ...bb_ProductFilters_Event
  }

  fragment ProductsPage on Product {
    id
    features # The features have to be queried outside of the list row because they are not typically shown.
    ...ProductListRow
    ...ProductCard
    ...Search_Product
    ...ProductFilters
    ...useLink_Product # TODO exhibitorId isn't available on RegisteredProduct.product, so we need to make sure it comes from Product at least.
  }

  fragment ProductsPage_first on Product {
    ...ProductListRow_first
    ...ProductListRow
    ...ProductCard_first
    ...ProductCard
  }

  fragment ProductsPage_Event on Event {
    ...EventBanner
  }

  fragment FieldValue on Bottlebooks_FieldValue {
    ... on Bottlebooks_TextFieldValue {
      text: value
    }
    ... on Bottlebooks_IntFieldValue {
      integer: value
    }
    ... on Bottlebooks_FloatFieldValue {
      number: value
    }
    ... on Bottlebooks_YesNoFieldValue {
      value
      rawText
    }
    ... on Bottlebooks_SelectFieldValue {
      text: value
    }
    ... on Bottlebooks_MultiSelectFieldValue {
      values: value(format: LOCALIZED)
    }
    ... on Bottlebooks_ChangeoverFieldValue {
      month: value
    }
  }
`;
