/** @jsx jsx */
import {
  Box,
  Container,
  Flex,
  Label,
  SemiTitle,
  Text,
  Title,
  View,
} from '@bottlebooks/gatsby-design-system';
import SliderImage from '@bottlebooks/gatsby-theme-cms/src/components/Sections/Slider/SliderImage';
import { jsx } from 'theme-ui';

export default function Slider({
  id = 'slider',
  image,
  lead,
  title,
  subtitle,
  height = [287, 325, 400, 550],
  ...rest
}) {
  return (
    <View id={id} sx={{ position: 'relative', height }} {...rest}>
      <SliderImage image={image} height={height}>
        {title && (
          <Container
            fluid
            sx={{
              maxWidth: 'container.fluid',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              height: '100%',
            }}
          >
            <Flex
              sx={{
                alignItems: 'flex-end',
                height: '100%',
              }}
            >
              <Box sx={{ paddingBottom: [3, 4] }}>
                <Text
                  sx={{
                    fontSize: 22,
                    color: 'white',
                    textShadow: '0px 2px 3px rgb(0 0 0 / 60%)',
                    whiteSpace: 'pre-line',
                    marginBottom: 2,
                  }}
                >
                  {lead}
                </Text>
                <Title
                  sx={{
                    color: 'white',
                    fontWeight: 700,
                    fontSize: [50, 60, 80],
                    lineHeight: 1.2,
                    textShadow: '0px 2px 3px rgb(0 0 0 / 60%)',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {title}
                </Title>
                <SemiTitle
                  sx={{
                    color: 'white',
                    // color: 'rgb(255, 184, 28);',
                    fontWeight: 700,
                    fontSize: [28, 32, 38],
                    lineHeight: 1,
                    textShadow: '0px 2px 3px rgb(0 0 0 / 60%)',
                    whiteSpace: 'pre-line',
                  }}
                >
                  {subtitle}
                </SemiTitle>
              </Box>
            </Flex>
          </Container>
        )}
      </SliderImage>
    </View>
  );
}
