/** @jsx jsx */
import { Box, Text } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';

export default function ProductNumber({ product, ...rest }) {
  if (!product.sortNumber) return null;
  return (
    <Box
      sx={{
        paddingY: 1,
        paddingX: 3,
        backgroundColor: 'light',
        borderRadius: 999,
        display: 'inline-block',
        border: 1,
        borderColor: 'border',
      }}
      {...rest}
    >
      <Text> {product.sortNumber}</Text>
    </Box>
  );
}
